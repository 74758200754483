import React from 'react'
import logo from './../../assets/Tarajia5.png'
import './footer.css'
import { Link } from 'react-router-dom'
import { BsFacebook } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsTwitter } from 'react-icons/bs'
import { FaTiktok } from 'react-icons/fa'
import { BsYoutube } from 'react-icons/bs'
import Home from './../../assets/logo2.png'

function Footer() {
    return (
        <section className="footer">
            <footer>

                <div class="container__footer">
                    <div class="box__footer">
                        <div class="logo">
                            <img src={logo} alt="" />
                        </div>
                        <div class="terms">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas impedit cum cumque velit libero officiis quam doloremque reprehenderit quae corporis! Delectus architecto officia praesentium atque laudantium, nam deleniti sapiente deserunt.</p>
                        </div>
                    </div>
                    <div class="box__footer">
                        <h2>About Taraji-a</h2>
                        <Link to='/register'>About Us</Link>
                        <Link to='/register'>
                            Returns and Refunds Policy</Link>
                        <Link to='/register'>Terms and Conditions</Link>
                        <Link to='/register'>Flash Sales</Link>
                    </div>

                    <div class="box__footer">
                        <h2>MAKE MONEY WITH US</h2>
                        <Link to='/register'>Sell on Taraji-a</Link>
                        <Link to='/register'>Become a Sales Consultant</Link>
                        <Link to='/register'>Advertise Your Products</Link>
                        <Link to='/register'>Fame your brand</Link>
                    </div>

                    <div class="box__footer">
                        <h2>LET US HELP YOU</h2>
                        <Link to='/register'> Help Center</Link>
                        <Link to='/register'> Contact Us</Link>
                        <Link to='/register'> How to shop on Taraji-a?</Link>
                        <Link to='/register'> Shipping and delivery</Link>
                        <Link to='/register'> Return Policy</Link>
                        <Link to='/register'> Dispute Resolution Policy</Link>
                        <Link to='/register'> Corporate and Bulk Purchase</Link>
                        <Link to='/register'> Advertise with Taraji-a</Link>
                        <Link to='/register'> Report a Product</Link>
                    </div>
                    <div class="box__footer">
                        <div className="wrap">
                            <div className="header">
                                <h1>Subscribe</h1>
                                <p>coming soon to your inbox</p>
                            </div>
                            <div className="footer">
                                <form action="">
                                    <input type="text" placeholder="Enter your email here" />
                                </form>
                                <button className="btn-submit">SEND</button>
                            </div>
                        </div>

                    </div>
                    <div class="box__footer">
                        <h2>Download the app!</h2>
                        <h4>"new feature soon!"</h4>
                        <section className="app">
                            <div className="column column1">
                                <Link to='/register'><img src="https://www.instagram.com/static/images/appstore-install-badges/badge_ios_english-en.png/180ae7a0bcf7.png" alt="Download App Store" /></Link>
                            </div>
                            <div className="column column2">
                                <Link to='/register'><img src="https://www.instagram.com/static/images/appstore-install-badges/badge_android_english-en.png/e9cd846dc748.png" alt="Download Google Play" /></Link>
                            </div>
                        </section>
                    </div>

                </div>
                <div className="social">
                    <ul class="wrapper">
                        <Link to='https://twitter.com/i/flow/login'>

                            <li class="icon facebook">
                                <span class="tooltip">Facebook</span>
                                <span>
                                    <BsFacebook />
                                </span>
                            </li>
                        </Link>
                        <Link to='/register'>

                            <li class="icon twitter">
                                <span class="tooltip">Twitter</span>
                                <span>
                                    <BsTwitter />
                                </span>
                            </li>
                        </Link>
                        <Link to='/register'>
                            <li class="icon instagram">
                                <span class="tooltip">Instagram</span>
                                <span>
                                    <BsInstagram />
                                </span>
                            </li>
                        </Link>
                        <Link to='/register'>
                            <li class="icon tiktok">
                                <span class="tooltip">TikTok</span>
                                <span>
                                    <FaTiktok />
                                </span>
                            </li>
                        </Link>
                        <Link to='/register'>

                            <li class="icon youtube">
                                <span class="tooltip">Youtube</span>
                                <span>
                                    <BsYoutube />
                                </span>
                            </li>
                        </Link>
                    </ul>
                </div>
                <div class="box__copyright">
                    <hr />
                    <Link to=''>
                        <div className="logo-footer">
                            <p>Engine Powered by : <img src={Home} alt="" /> <b>e|ncript</b> © 2023 </p>
                        </div>
                    </Link>
                </div>
            </footer>
        </section>
    )
}

export default Footer