import React from 'react'
import './virtuals.css'
import shade from './../../../assets/shade.png'
import Before from './../../../assets/before.png'
import After from './../../../assets/after.png'
import ReactCompareImage from 'react-compare-image'

function Virtuals() {
    return (
        <section className="Virtual">
            <div className="v-left">
                <span>Virtual Try-on</span>
                <span>Never Buy The Wrong shade Again!</span>
                <span>Try Now!</span>
                <img src={shade} alt="" />
            </div>
            <div className="wrapper-compare">
                <div className="v-right">
                    <ReactCompareImage leftImage={Before} rightImage={After} />
                </div>
            </div>
        </section>
    )
}

export default Virtuals