import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productsService from "./productsService";

// @****************************************@
// ---------------Initial state ----
// @****************************************@
const initialState = {
    productData: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// @****************************************@
// ---------------GET SIngle PRODUCT ----
// @****************************************@

export const getSingleProducts = createAsyncThunk('productList/getAllProducts', async (productId, thunkAPI) => {
    try {
        return await productsService.getSingleProduct(productId)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})






// @****************************************@
// ---------------SLICE ----
// @****************************************@


export const productSlice = createSlice({
    name: 'productList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSingleProducts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSingleProducts.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.productData = action.payload
            })
            .addCase(getSingleProducts.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.productData = []
            })
    }
})






// @****************************************@
// ---------------EXPORT ACTIONS AND SLCE ----
// @****************************************@

// export const { } = productsSlice.actions
export default productSlice.reducer