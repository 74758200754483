import React from 'react'
import './main.css'
import { TbBrandWindows } from 'react-icons/tb'
import Slider from './Sliders/Slider'
import Virtuals from './Virtuals/Virtuals'
import ProductCategories from './ProductsCategories/ProductCategories'
import { Link } from "react-router-dom"
import Highlights from "./Highlights/Highlights";
import TopHeader from "./TopHeader/TopHeader";

function Main() {
  const tbBrandWindows = {
    color: "white",
    fontSize: "35px",
    backgroundColor: "#360c49",
    borderRadius: "50px",
    padding: ".2rem",
  }

  return (
    <div className='cover-all'>
      <div className="categories">
        <div className="categoriesData">
          <Link className='menu-links'>
            <TbBrandWindows style={tbBrandWindows} />
            <div className="menu-cover">
              <div className="menu-list">
                <nav>
                  <ul>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Beauty And Health</span>
                        </div>
                        <div className="menu2-link">

                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Hair</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Baby,Kids & Maternity</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Womens Fashion</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Mens Fashion</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Kids Fashion</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Watches & Jewellery</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Shoes</span>
                        </div>
                        <div className="menu2-link"></div>
                      </Link>
                    </li>
                    <li>
                      <Link>
                        <div className="menu2">
                          <span>Fitness</span>
                          <div className="menu2-link"></div>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <section className="Main2">
        <TopHeader />
      </section>
      <div className='cover-links'>
        <Highlights />
        <Slider />
        <Virtuals />
        <Slider />
        <ProductCategories />
        <Slider />
        <Slider />
      </div>
    </div>
  )
}

export default Main