import * as React from 'react';
import './Header.css'
import Tarajia from '../../assets/Tarajia6.png'
import { RiSearch2Fill } from 'react-icons/ri'
import { HiOutlineShoppingBag } from 'react-icons/hi'
import { FaUserAlt } from 'react-icons/fa'
import { FaUser } from 'react-icons/fa'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { logOut, reset } from './../../features/auth/authSlice'
// import Spinner from '../Spinner/Spinner';
import { useEffect } from 'react'


function Header() {
  const searchStyle = {
    color: "black",
    fontSize: "25px"
  }
  const cart = {
    color: "black",
    fontSize: "25px"
  }
  const user1 = {
    color: "black",
    fontSize: "30px",
    backgroundColor: "#ffffff",
    borderRadius: "50px",
    padding: ".4rem",
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isError, isSuccess, message } = useSelector((state) => state.auth)

  const { cartTotalQuantity } = useSelector((state) => state.cart)

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

  }, [user, isError, isSuccess, message,])
  const onLogout = () => {
    dispatch(logOut())
    dispatch(reset())
    navigate('/')
  }
  return (


    <header className='H-Navigation'>
      <div className="menu">
        <Link to='/'>
          <div className="Logo">
            <img src={Tarajia} alt="Tarajia" />
          </div>
        </Link>
      </div>

      <div className="search-bar">
        <div className="box">
          <input type="text" placeholder='Search...' />
          <button><RiSearch2Fill style={searchStyle} /></button>
        </div>
      </div>
      <nav className="headerNav">
        <ul className='headerNav-ul'>
         <Link to='/cart'>
          <li className="cartButton">
              <HiOutlineShoppingBag style={cart} className='cart-icon' />
              <div className="cart"><span>{cartTotalQuantity}</span></div>
            </li>
         </Link>

          {user ? (
            <>
              <h5>Hi {user.username}</h5>
              <li>
                <Link to='' className='linkTo' onClick={onLogout}><FaUser style={user1} />LogOut</Link>
              </li>
            </>

          ) : (
            <>
              <li>
                <Link to='/login' className='linkTo'><FaUserAlt style={user} /><span>LogIn</span></Link>
              </li>
              <li>
                <Link to='/register' className='linkTo'><FaUser style={user1} />SignUp</Link>
              </li>
            </>
          )
          }

        </ul >
      </nav >
    </header >
  )
}

export default Header