import React, { useEffect } from 'react';
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import Header from "./components/Header/Header"
import Main from './components/Main/Main'
import Footer from './components/Footer/Footer'
import Register from './components/Register/Register'
import Login from './components/Login/Login'
import ProductId from './components/ProductId/ProductId';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Products from './components/Products/Products'
import Cart from './components/Cart/Cart'
import { useDispatch, useSelector } from 'react-redux';
import { getTotals } from './features/Cart/cartSlice';
import Uploads from './components/Uploads/Uploads'
import Order from './components/Order/Order'

function App() {
  const dispatch = useDispatch()
  const cart = useSelector(state => state.cart)

  useEffect(() => {
    dispatch(getTotals())
  }, [cart, dispatch])


  const Layout = () => {
    return (
      <div className="mainStore">
        <div className="navbar"><Header /></div>
        <div className="main">
          <Outlet />
        </div>
        <div className="footer"><Footer /></div>
        {/* <ToastContainer /> */}
      </div>
    )
  }


  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: (
            <div className="homeStore">
              <Main />
              <ToastContainer />
            </div>
          )
        },
        {
          path: '/products/:category',
          element: (
            <div className="homeStore">
              <Products />
            </div>
          )
        },
        {
          path: '/product/:id',
          element: (
            <div className="homeStore">
              <ProductId />
            </div>
          )
        },
        {
          path: '/cart',
          element: (
            <div className="homeStore">
              <Cart />
            </div>
          )
        },
      ]
    },
    {
      path: '/login',
      element: (
        <div className="homeStore">
          <Login />
          <ToastContainer />
        </div>
      )
    },
    {
      path: '/register',
      element: (
        <div className="homeStore">
          <Register />
          <ToastContainer />
        </div>
      )
    },
    {
      path: '/upload',
      element: (
        <div className="homeStore">
          <Uploads />
          <ToastContainer />
        </div>
      )
    },
    {
      path: '/billing',
      element: (
        <div className="homeStore">
          <Order />
          <ToastContainer />
        </div>
      )
    },
  ])




  return <RouterProvider router={router} />
  // <>
  //   <Router>
  //     <div className="App">
  //       <Header />
  //       <Routes>
  //         <Route path='/' element={<Main />} />
  //         <Route path='/products/:category' element={<Products />} />
  //         <Route path='/product/:id' element={<ProductId />} />
  //         <Route path='/login' element={<Login />} />
  //         <Route path='/register' element={<Register />} />
  //         <Route path='/cart' element={<Cart />} />
  //         <Route path='/upload' element={<Uploads />} />
  //       </Routes>
  //       <Footer />
  //     </div>
  //   </Router>
  //   <ToastContainer />
  // </>


}

export default App;
