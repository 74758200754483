export const statusCarousel = [
    {
        img:"https://i.pinimg.com/originals/c0/4b/01/c04b017b6b9d1c189e15e6559aeb3ca8.png",
        name:"health"
    }, 
    {
        img:"https://cdn-icons-png.flaticon.com/512/706/706830.png",
        name:"oils"
    },  
    {
        img:"https://i.pinimg.com/564x/84/ed/52/84ed529add312d7b5f3fd281d21f8cfe.jpg",
        name:"women"
    },
    {
        img:"https://cdn-icons-png.flaticon.com/512/1177/1177568.png",
        name:"watches"
    }, 
    {
        img:"https://cdn-icons-png.flaticon.com/512/706/706830.png",
        name:"wigs"
    }, 
    {
        img: "https://cdn-icons-png.flaticon.com/512/706/706816.png",
        name:"nails"
    }, 
    {
        img:"https://cdn-icons-png.flaticon.com/512/805/805370.png",
        name:"food"
    }, 
    {
        img:"https://i.pinimg.com/originals/c0/4b/01/c04b017b6b9d1c189e15e6559aeb3ca8.png",
        name:"toys"
    }, 
    {
        img:"https://cdn-icons-png.flaticon.com/512/706/706830.png",
        name:"perfumes"
    }, 
    {
        img:"https://cdn-icons-png.flaticon.com/512/706/706816.png",
        name:"oils"
    }, 
    {
        img:"https://cdn-icons-png.flaticon.com/512/805/805370.png",
        name:"nails"
    }, 
    ];