import { useCallback, useEffect, useState } from "react";
import "./Uploads.css";
import app from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { resetUpload, uploadProduct } from './../../features/Products/upload'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from 'react-toastify'
import Spinner from "../Spinner/Spinner";
import { Link } from "react-router-dom";



export default function Uploads() {

  const [cat, setCat] = useState([]);
  const dispatch = useDispatch();

  const [productInputs, setProductInputs] = useState({})
  const [img, setImg] = useState(undefined)
  const [imgPerc, setImgPerc] = useState(0)
  // const [videoPerc, setVideoPerc] = useState(0)

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.uploads
  )
  const handleChange = (e) => {
    setProductInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleCat = (e) => {
    setCat(e.target.value.split(","))
  };

  const uploadFile = useCallback(async(file, fileType) => {
    const storage = getStorage(app);

    // Upload file and metadata to the object 'images/mountains.jpg'
    const folder = fileType === "imgUrl" ? "images/" : "videos/"
    const fileName = new Date().getTime() + file.name;
    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on('state_changed',
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        fileType === "imgUrl" ? setImgPerc(Math.round(progress)) : setImgPerc(Math.round(progress))
        // console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;

          default:
            console.log('Upload is loading');
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;

          // ...

          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;

          default:
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
          setProductInputs((prev) => {
            return {
              ...prev,
              img: downloadURL,
              categories: cat,
            }
          })
        })
      }
    )
  },[cat])

  useEffect(() => {
    img && uploadFile(img, "imgUrl")
    if (isError) {
      toast.error(message)
    }

    if (isSuccess) {
      toast.success("Product successfully Uploaded", {
        position: toast.POSITION.TOP_CENTER,
      })
    }

    dispatch(resetUpload())
  }, [img, dispatch, message, isError, isSuccess, uploadFile])



  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(uploadProduct(productInputs));
    // Clear form fields or redirect to another page after successful submission
  };

  if (isLoading) {
    return <Spinner />
  }



  return (
    <div className="newProduct">
      <Link to='/'>
        <h1 className="addProductTitle">
          New Product
        </h1>
      </Link>
      <form className="addProductForm" onSubmit={handleSubmit}>
        <div className="addProductItem">
          <label>Image</label> {imgPerc > 0 && "Uploading:" + imgPerc + "%"}
          <input
            name="img"
            type="file"
            accept="image/*"
            id="img"
            onChange={(e) => setImg((prev) => e.target.files[0])}
            required="Choose an Image"
          />
        </div>
        <div className="addProductItem">
          <label>Title</label>
          <input
            name="title"
            type="text"
            placeholder="Apple Airpods"
            onChange={handleChange}
            required="Give product a name"
          />
        </div>
        <div className="addProductItem">
          <label>Description</label>
          <input
            name="desc"
            type="text"
            placeholder="description..."
            onChange={handleChange}
            required="Give product a Description"
          />
        </div>
        <div className="addProductItem">
          <label>Price</label>
          <input
            name="price"
            type="number"
            placeholder="100"
            onChange={handleChange}
            required="Give product a Price"
          />
        </div>
        <div className="addProductItem">
          <label>Categories</label>
          <input name="categories" type="text" placeholder="jeans,skirts" onChange={handleCat} required="Assign product a category" />
        </div>
        <div className="addProductItem">
          <label>Is in Stock</label>
          <select name="inStock" onChange={handleChange} >
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>
        <button type="submit" className="addProductButton">
          Create
        </button>
      </form>
    </div>
  );
}
