import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Highlights from '../Main/Highlights/Highlights'
import './products.css'

import EachItem from './EachItem'
import { useDispatch, useSelector } from 'react-redux'
import { getAllProducts } from '../../features/Products/productsSlice'
import Spinner from './../Spinner/Spinner'


function Products() {
    const { products, isLoading } = useSelector(
        (state) => state.productsCollection
    )

    // const [recentProducts, setRecentProducts] = useState(SliderProducts)
    // const [productCategory, setProductCategory] = useState(ProductsData)
    // const [products, setProducts] = useState([])
    // const filter = (type) => {
    //     setProductCategory(ProductsData.filter((product) => product.type === type))

    // }
    const dispatch = useDispatch()
    const location = useLocation()
    const cat = location.pathname.split("/")[2]

    useEffect(() => {
        if (cat) {
            dispatch(getAllProducts(cat))
        }
    }, [cat, dispatch])

    return (
        <div className="products-view">
            <Highlights />
            <div className="products-all">
                <div className="product-filter">
                    <nav className="filter">
                        {/* <ul>
                            <Link to='' className='filter-link' onClick={() => setProductCategory(ProductsData)}> All </Link>
                            <Link to='' className='filter-link' onClick={() => filter("skin care")}> Beauty & Health </Link>
                            <Link className='filter-link' onClick={() => filter("conditioner")}> Hair </Link>
                            <Link className='filter-link' onClick={() => filter("foundation")}> Baby, Kids & Maternity </Link>
                            <Link className='filter-link' onClick={() => filter("skin care")}> Clothes </Link>
                            <Link className='filter-link' onClick={() => filter("conditioner")}> Jewelleries </Link>
                            <Link className='filter-link' onClick={() => filter("foundation")}> Watches </Link>
                            <Link className='filter-link' onClick={() => filter("skin care")}> Adults </Link>
                        </ul> */}
                    </nav>
                </div>
                <div className="products-data">
                    {isLoading && <Spinner />}
                    {
                        products.map((product) => (
                            <div className='eachProduct'>
                                <EachItem key={product._id} product={product} />
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="featered-products">
                <h3>Recently Viewed Products</h3>
                <div className="featured-data">
                    {/* {
                        recentProducts.map((product, i) => (
                            <div className='eachProduct'>
                                <img src={product.img} alt="product" className='img-p' />
                                <div className="coverProduct">
                                    <div className="name">
                                        <div className="view">Let's test</div>
                                        <span className="productTitle">{product.name}</span>
                                        <span className="productDetails">{product.detail}</span>
                                    </div>

                                    <span className="productPrice">Ksh {product.price} </span>

                                    <div className="buyButton">
                                        <MdOutlineAddShoppingCart />
                                    </div>
                                </div>
                            </div>
                        ))
                    } */}
                </div>
            </div>
            <div className="featered-products">
                <h3>Related Products</h3>
                <div className="featured-data">
                    {/* {
                        recentProducts.map((product, i) => (
                            <div className='eachProduct'>
                                <img src={product.img} alt="product" className='img-p' />
                                <div className="coverProduct">
                                    <div className="name">
                                        <span className="productTitle">{product.name}</span>
                                        <span className="productDetails">{product.detail}</span>
                                    </div>

                                    <span className="productPrice">Ksh {product.price} </span>

                                    <div className="buyButton">
                                        <MdOutlineAddShoppingCart />
                                    </div>
                                </div>

                            </div>
                        ))
                    } */}
                </div>
            </div>
        </div >
    )
}

export default Products