import React from 'react'
import "./highlights.css";
import Carousel from './Carousel';

function Highlights() {
  return (
    <section className='carousel-status'>
      <Carousel />
    </section>

  )
}

export default Highlights