import React from 'react'
import { useEffect } from 'react'
import { TiMinus } from 'react-icons/ti'
import { TiPlus } from 'react-icons/ti'
import { MdOutlineDeleteSweep } from 'react-icons/md'
import { MdDeleteSweep } from 'react-icons/md'
import './cart.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addProduct, clearCart, decreaseQuantity, getTotals, removeProduct } from '../../features/Cart/cartSlice'



const Cart = () => {
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const cart = useSelector(
        (state) => state.cart
    )

    useEffect(() => {
        dispatch(getTotals())
    }, [dispatch])

    const handleDecreaseQuantity = (product) => {
        dispatch(decreaseQuantity(product))
    }

    const handleIncreaseQuantity = (product) => {
        dispatch(addProduct(product))
    }

    const handleRemoveFromCart = (product) => {
        dispatch(removeProduct(product))
    }

    const handleClearCart = () => {
        dispatch(clearCart())
    }


    return (
        <>
            <div className="cart-section">
                <div className="products-section">
                    {cart.products.length === 0 ? (
                        <div className="cart-empty">
                            <h4>Opps! Your cart is Empty!</h4>
                            <Link to='/'> check some amazing deals from there!</Link>
                        </div>
                    ) : (
                        <div className="product-cover-main">
                            {cart.products.map(product => (
                                <div className="product-cover">
                                    <div className="product-cart-image">
                                        <img src={product.img} alt="nike" />
                                    </div>
                                    <div className="cart-product-details">
                                        <h3 className="p-title">{product.title}</h3>
                                        <h6 className="p-price">Ksh {product.price}</h6>
                                        <h8 className="p-status">{product.size}</h8>
                                    </div>
                                    <div className="cart-product-price">
                                        <div className="product-quantity">
                                            <div className="decrease" onClick={() => handleDecreaseQuantity(product)}>
                                                <TiMinus />
                                            </div>
                                            <div className="product-amount">
                                                <span>{product.cartQuantity}</span>
                                            </div>
                                            <div className="increase" onClick={() => handleIncreaseQuantity(product)}>
                                                <TiPlus />
                                            </div>
                                        </div>
                                        <div className="total-product-price">
                                            <span>Ksh {product.price * product.cartQuantity}</span>
                                            <div className="product-delete" onClick={() => handleRemoveFromCart(product)}>
                                                <MdOutlineDeleteSweep />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="clear-my-cart" onClick={() => handleClearCart()}>
                                <MdDeleteSweep />Clear My Cart
                            </div>
                        </div>
                    )}
                </div>
                <div className="total-payment-section">

                    <div className="SubTotal">
                        <div className="title">Subtotal:</div>
                        <div className="Amount">Ksh {cart.cartTotalAmount}</div>
                    </div>
                    <div className="Info">
                        <div className="Data">Taxes and Shipping Will be Calculated at checkout</div>
                        <Link to='/billing'><button>Checkout</button></Link>
                    </div>
                    {/* <div className="Info">
                        <Link to='/'>Continue Shopping </Link>
                    </div> */}
                </div>
            </div >
        </>
    )
}

export default Cart
