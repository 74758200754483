import axios from "axios"


const API_URL = 'https://abanchiqstoreapi.onrender.com/api/products'
// const API_URLS = 'http://206.189.121.153:9091/api/products/product/category-name?categoryName=clothes'

// @****************************************@
// ---------------GET ALL PRODUCTS ----
// @****************************************@


const getProducts = async (cat) => {
    const response = await axios.get(cat ? `${API_URL}?category=${cat}` : API_URL)
    return response?.data
}
const uploadProducts = async (productInputs) => {
    const response = await axios.post(API_URL, productInputs)
    return response?.data
}

const getSingleProduct = async (productId) => {
    const response = await axios.get(`${API_URL}/find/${productId}`)
    return response?.data
}


























// @****************************************@
// ---------------Bundle and Export all products-services module ----
// @****************************************@
const productsService = {
    getProducts,
    getSingleProduct,
    uploadProducts
}



export default productsService