import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import productsService from "./productsService";

// @****************************************@
// ---------------Initial state ----
// @****************************************@
const initialState = {
    product: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}


// @****************************************@
// ---------------UploadPRODUCTS ----
// @****************************************@

export const uploadProduct = createAsyncThunk('newProduct/uploadProduct', async (productInputs, thunkAPI) => {
    try {
        return await productsService.uploadProducts(productInputs)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})







// @****************************************@
// ---------------SLICE ----
// @****************************************@


export const uploadproductsSlice = createSlice({
    name: 'newProduct',
    initialState,
    reducers: {
        resetUpload: (state) => {
            state.product = []
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadProduct.pending, (state) => {
                state.isLoading = true
            })
            .addCase(uploadProduct.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.product = action.payload
            })
            .addCase(uploadProduct.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.product = []
            })

    }
})






// @****************************************@
// ---------------EXPORT ACTIONS AND SLCE ----
// @****************************************@

// export const { } = productsSlice.actions
export const { resetUpload } = uploadproductsSlice.actions
export default uploadproductsSlice.reducer