import React from 'react'
import './slider.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { SliderProducts } from './../../../data/products'

function Slider() {
    return (
        <div className="slider-container">

            <Swiper
                modules={[Pagination, Navigation]}
                className='mySwiper'
                loopFillGroupWithBlank={true}
                grabCursor={true}
                navigation={true}
                slidesPerView={6}
                spaceBetween={10}
                slidesPerGroup={1}
                loop={true}
            >
                {SliderProducts.map((slide, i) => (
                    <SwiperSlide>
                        <div className="left-s">
                            <div className="name">
                                <span>{slide.name}</span>
                                <span>{slide.detail}</span>
                            </div>
                            <span>{slide.price}Ksh</span>
                            <div className="shop">Shop Now</div>
                        </div>
                        <img src={slide.img} alt="product" className='img-p' />
                    </SwiperSlide>

                ))}
            </Swiper>
        </div>
    )
}

export default Slider