import React, { useEffect } from 'react'

import './product.css'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleProducts } from '../../features/Products/productSlice'
import { addProduct } from '../../features/Cart/cartSlice'
import Spinner from './../Spinner/Spinner'

function ProductId() {

    const { productData, isLoading} = useSelector(
        (state) => state.productItem
    )

    // const cart = useSelector(state => state.cart)
    const dispatch = useDispatch()
    const location = useLocation()
    const productId = location.pathname.split("/")[2]

    useEffect(() => {
        if (productId) {
            dispatch(getSingleProducts(productId))
        }
    }, [productId, dispatch])



    const handleClickCart = () => {
        dispatch(addProduct({ ...productData }))
    }
    if (isLoading) {
        return <Spinner />
    }

    return (
        // <div className='individualProduct'>ProductId</div>

        <div class="individualProduct">
            {/* {isLoading && <Spinner />} */}
            <div class="product__photo">
                <div class="photo-container">
                    <div class="photo-main">
                        <div class="controls">
                            <Link to='/' className='Link'>Back</Link>
                        </div>
                        <img src={productData.img} alt={productData._id} />
                    </div>
                    {/* <div class="photo-album">
                        <ul>
                            <li><img src={oil} alt="imgType1" /></li>
                            <li><img src={oil} alt="imgType2" /></li>
                            <li><img src={oil} alt="imgType3" /></li>
                            <li><img src={oil} alt="imgType4" /></li>
                        </ul>
                    </div> */}
                </div>
            </div>
            <div className="description">
                <p>{productData.desc}</p>
                <div class="variant">
                    <h3>Choose Your Variety</h3>
                    <ul>
                        <li><img src={productData.img} alt="imgType1" /></li>
                        <li><img src={productData.img} alt="imgType2" /></li>
                        <li><img src={productData.img} alt="imgType3" /></li>
                        <li><img src={productData.img} alt="imgType4" /></li>
                    </ul>
                </div>
            </div>
            <div class="product__info">
                <div class="title">
                    <h1>{productData.title}</h1>
                </div>
                <div class="price">
                    Ksh <span>{productData.price}</span>
                </div>

                <button class="buy--btn" onClick={handleClickCart}>ADD TO CART</button>
            </div>

        </div>
    )
}

export default ProductId