import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


// @****************************************@
// ---------------Initial state ----
// @****************************************@
const initialState = {
    products: localStorage.getItem("products") ? JSON.parse(localStorage.getItem("products")) : [],
    cartTotalQuantity: 0,
    cartTotalAmount: 0,
    taxShipping: 0.1
}


// @****************************************@
// ---------------SLICE ----
// @****************************************@


const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addProduct: (state, action) => {

            const itemIndex = state.products.findIndex(item => item._id === action.payload._id)
            if (itemIndex >= 0) {
                state.products[itemIndex].cartQuantity += 1
                toast.info(`You've increased ${state.products[itemIndex].title} quantity`, {
                    position: "bottom-center"
                })
            }
            else {
                const tempProduct = { ...action.payload, cartQuantity: 1 }
                state.products.push(tempProduct)
                toast.info(`Added ${action.payload.title} to cart`, {
                    position: "bottom-center"
                })
            }


            localStorage.setItem("products", JSON.stringify(state.products))

            // state.quantity += 1
            // state.subTotal += action.payload.price * action.payload.quantity
        },
        removeProduct: (state, action) => {
            const nextCartItems = state.products.filter(
                cartProduct => cartProduct._id !== action.payload._id
            )
            state.products = nextCartItems
            localStorage.setItem("products", JSON.stringify(state.products))
            toast.error(`Removed ${action.payload.title} from cart`, {
                position: "bottom-center"
            })
        },

        decreaseQuantity: (state, action) => {
            const itemIndex = state.products.findIndex(item => item._id === action.payload._id)
            if (state.products[itemIndex].cartQuantity > 1) {
                state.products[itemIndex].cartQuantity -= 1

                toast.info(`You've decreased ${action.payload.title} quantity.`, {
                    position: "bottom-center"
                })
            } else if (state.products[itemIndex].cartQuantity === 1) {
                const nextCartItems = state.products.filter(
                    cartProduct => cartProduct._id !== action.payload._id
                )
                state.products = nextCartItems
                toast.error(`Removed ${action.payload.title} from cart`, {
                    position: "bottom-center"
                })
            }
            localStorage.setItem("products", JSON.stringify(state.products))
        },
        clearCart: (state, action) => {
            state.products = []
            toast.error(`You've cleared your cart`, {
                position: "bottom-center"
            })
            localStorage.setItem("products", JSON.stringify(state.products))
        },

        getTotals: (state, action) => {
            let { total, quantity } = state.products.reduce(
                (cartTotal, product) => {
                    const { price, cartQuantity } = product
                    const productTotal = price * cartQuantity

                    cartTotal.total += productTotal
                    cartTotal.quantity += cartQuantity

                    return cartTotal
                },
                {
                    total: 0,
                    quantity: 0,
                }
            )
            state.cartTotalQuantity = quantity
            state.cartTotalAmount = total
        }
    },

})







// @****************************************@
// ---------------EXPORT ACTIONS AND SLICE ----
// @****************************************@

export const { addProduct, removeProduct, decreaseQuantity, clearCart, getTotals } = cartSlice.actions
export default cartSlice.reducer