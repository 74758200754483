import React from 'react'
import Avatar from './Avatar';
import './highlights.css';
import { statusCarousel } from './data';
import rightOne from './icons/angle-right-solid.svg'
import leftOne from './icons/angle-left-solid.svg'

const Carousel = () => {
    const handleSlide = (direction)=>{
        const slider = document.getElementsByClassName('carousel-body')[0];
        if (direction === "left")
            slider.scrollBy(-800, 0);
        else  
            slider.scrollBy(800,0);
    }

    return (
        <section className='check'>
            <div className='arrow-btn left-icon' onClick={()=>handleSlide('left')}>
                <img src={leftOne} alt="left-angle" />
            </div>
            <div className='arrow-btn right-icon' onClick={()=>handleSlide('right')} >
                <img src={rightOne} alt="right-angle" />
            </div>
            <div className="carousel-body">
                {
                    statusCarousel.map((item)=>{
                        return <Avatar key={item} image={item} />
                    })
                }
            </div>
        </section>
    )
}

export default Carousel