import React from 'react'
import './products.css'
import { Link } from 'react-router-dom'
import { RiListCheck2 } from 'react-icons/ri'
import { MdOutlineAddShoppingCart } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { addProduct } from './../../features/Cart/cartSlice'


function EachItem({ product }) {
    const dispatch = useDispatch()

    const handleAddCart = (product) => {
        dispatch(addProduct(product))
    }

    return (
        <div className='eachProductData'>
            <img src={product.img} alt="product" className='img-p' />
            <div className="coverProduct">
                <div className="name">
                    <span className="productTitle">{product.title}</span>
                    <span className="productDetails">{product.desc}</span>
                </div>
                <span className="productPrice">Ksh {product.price} </span>
                <Link to={`/product/${product._id}`}>
                    <div className="buyButton">
                        <RiListCheck2 />
                    </div>
                </Link>
                <Link onClick={() => handleAddCart(product)}>
                    <div className="seeButton">
                        <MdOutlineAddShoppingCart />
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default EachItem