import axios from 'axios'
// "proxy": "http://206.189.121.153:9091",
// const API_URL = '/api/auth/'
// const API_URL = 'http://localhost:8080/api/auth/'

const API_URL = 'https://abanchiqstoreapi.onrender.com/api/auth/'
// const API_URL = '/api/user/'

// REGISTER USER
const register = async (userData) => {
    const response = await axios.post(API_URL + 'register', userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}
// LOGIN USER
const login = async (userData) => {
    const response = await axios.post(API_URL + 'login', userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}


// LOGOUT
const logOut = () => {
    localStorage.removeItem('user')
}


const authService = {
    register,
    login,
    logOut,
}


export default authService