import React from 'react'
import "./topheader.css";
import { Link } from 'react-router-dom';


function TopHeader() {
    return (
        <div className="top-cover">
            <section>

                <div class="card">
                    <Link to='/products/women'>
                        <div class="card__img">
                            <img src="https://images.unsplash.com/photo-1513094735237-8f2714d57c13?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NTh8fHNob3B8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60" alt="Big Ben" />
                            <div class="card__overlay">
                                <h2>Women</h2>
                                <p>Shop Now</p>
                            </div>
                        </div>
                    </Link>
                </div>

                <div class="card">
                    <Link to='/products/clothes'>
                        <div class="card__img">
                            <img src="https://images.unsplash.com/photo-1582719188393-bb71ca45dbb9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODR8fHNob3B8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60" alt="Eiffel Tower" />

                            <div class="card__overlay">
                                <h2>Clothes</h2>
                                <p>Explore Now</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="card">
                    <Link to='/products/oils'>
                        <div class="card__img">
                            <img src="https://images.unsplash.com/photo-1622976367834-df0e62e75237?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fG9pbHN8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60" alt="Colosseum" />

                            <div class="card__overlay">
                                <h2>Oils</h2>
                                <p>Shop Now</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="card">
                    <Link to='/products/hair'>
                        <div class="card__img">
                            <img src="https://images.unsplash.com/photo-1560869713-7d0a29430803?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fGhhaXIlMjBjYXJlfGVufDB8MXwwfHw%3D&auto=format&fit=crop&w=400&q=60" alt="Pisa Tower" />

                            <div class="card__overlay">
                                <h2>Hair</h2>
                                <p>Explore</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="card">
                    <Link to='/products/Shoes'>
                        <div class="card__img">
                            <img src="https://images.unsplash.com/photo-1595950653106-6c9ebd614d3a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8c2hvZXN8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60" alt="" />

                            <div class="card__overlay">
                                <h2>Sneakers</h2>
                                <p>Show All</p>
                            </div>
                        </div>
                    </Link>
                </div>
                <div class="card">
                    <Link to='/products/watches'>
                        <div class="card__img">
                            <img src="https://images.unsplash.com/photo-1612042850052-22573fcd0bad?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjN8fHdhdGNoZXN8ZW58MHwxfDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60" alt="Sydney Opera House" />

                            <div class="card__overlay">
                                <h2>Watches</h2>
                                <p>Explore More</p>
                            </div>
                        </div>
                    </Link>
                </div>

            </section>

        </div>
    )
}

export default TopHeader