// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAuJ8gu7bS9_guMzXBAzGehnOX2tzymryM",
    authDomain: "tarajia-8fa2b.firebaseapp.com",
    projectId: "tarajia-8fa2b",
    storageBucket: "tarajia-8fa2b.appspot.com",
    messagingSenderId: "249540984741",
    appId: "1:249540984741:web:ec54bcc9af2fd53899b24c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export default app;