import { configureStore } from '@reduxjs/toolkit';
import authReducer from './../features/auth/authSlice'
import productsReducer from '../features/Products/productsSlice';
import productReducer from '../features/Products/productSlice';
import cartReducer from './../features/Cart/cartSlice'
import uploadproductsReducer from '../features/Products/upload';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    productsCollection: productsReducer,
    productItem: productReducer,
    cart: cartReducer,
    uploads: uploadproductsReducer,
  },
});
