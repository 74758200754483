import React, { useEffect } from 'react'
import './Order.css'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import { getTotals } from '../../features/Cart/cartSlice'



function Order() {

    const navigate = useNavigate()
    const location = useLocation()
    // const dispatch = useDispatch()
    // const state = store.getState()
    const { user } = useSelector((state) => state.auth)


    // const [prevPath, setPrevPath] = useState('');
    const cart = useSelector(
        (state) => state.cart
    )

    const taxableFee = 0.1 * cart.cartTotalAmount

    const totalCost = taxableFee + cart.cartTotalAmount

    useEffect(() => {


        if (!user) {
            // return <Navigate to='/' state={{ prevUrl: location.pathname }} />
            return navigate('login', {
                state: { prevUrl: location.pathname }
            })
        }


    }, [user,navigate,location.pathname])

    return (
        <div className="orders">
            <header>
                <Link to='/cart' className='linkCart'>
                    Back To Cart
                </Link>
            </header>
            <div className='main'>
                <section className="checkout-form">
                    <form action="#!" method="get">
                        <h6>Contact information</h6>
                        <div className="form-control">
                            <label for="checkout-email">E-mail</label>
                            <div>
                                <span className="fa fa-envelope"></span>
                                <input type="email" id="checkout-email" name="checkout-email" placeholder="Enter your email..." />
                            </div>
                        </div>
                        <div className="form-control">
                            <label for="checkout-phone">Phone</label>
                            <div>
                                <span className="fa fa-phone"></span>
                                <input type="tel" name="checkout-phone" id="checkout-phone" placeholder="Enter you phone..." />
                            </div>
                        </div>
                        <br />
                        <h6>Shipping address</h6>
                        <div className="form-control">
                            <label for="checkout-name">Full name</label>
                            <div>
                                <span className="fa fa-user-circle"></span>
                                <input type="text" id="checkout-name" name="checkout-name" placeholder="Enter you name..." />
                            </div>
                        </div>
                        <div className="form-control">
                            <label for="checkout-address">Address</label>
                            <div>
                                <span className="fa fa-home"></span>
                                <input type="text" name="checkout-address" id="checkout-address" placeholder="Your address..." />
                            </div>
                        </div>
                        <div className="form-control">
                            <label for="checkout-city">City</label>
                            <div>
                                <span className="fa fa-building"></span>
                                <input type="text" name="checkout-city" id="checkout-city" placeholder="Your city..." />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="form-control">
                                <label for="checkout-country">Country</label>
                                <div>
                                    <span className="fa fa-globe"></span>
                                    <input type="text" name="checkout-country" id="checkout-country" placeholder="Country" list="country-list" />
                                    <datalist id="country-list">
                                        <option value="India"></option>
                                        <option value="USA"></option>
                                        <option value="Russia"></option>
                                        <option value="Japan"></option>
                                        <option value="Egypt"></option>
                                    </datalist>
                                </div>
                            </div>
                            <div className="form-control">
                                <label for="checkout-postal">Postal code</label>
                                <div>
                                    <span className="fa fa-archive"></span>
                                    <input type="numeric" name="checkout-postal" id="checkout-postal" placeholder="Postal Code" />
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <section className="checkout-details">


                    {cart.products.lenght === 0 ? (
                        <div className="checkout-details-inner">
                            <div className="checkout-lists">
                                <h3>Opps! you have no products</h3>
                            </div>
                            <div className="checkout-shipping">
                                <h6>subTotal: Ksh {cart.cartTotalAmount}</h6>
                                <p>Ksh 19</p>
                            </div>
                            <div className="checkout-shipping">
                                <h6>+ Delivery Fee </h6>
                                <h6>+ Shipping Fee </h6>
                                <h6>+ Tax & VAT </h6>
                                <p>Ksh {taxableFee}</p>
                            </div>
                            <div className="checkout-total">
                                <h6>Total</h6>
                                <p>Ksh: {totalCost}</p>
                            </div>

                        </div>
                    ) : (
                        <div className="checkout-details-inner">
                            <div className="checkout-lists">
                                {cart.products.map(product => (
                                    <div className="card">
                                        <div className="card-image"><img src={product.img} alt="pic" /></div>
                                        <div className="card-details">
                                            <div className="card-name">{product.title}</div>
                                            <div className="card-price">Ksh {product.price}</div>

                                            {/* <button>-</button>
                                        <span>1</span>
                                        <button>+</button> */}

                                        </div>
                                    </div>

                                ))}
                            </div >


                            <div className="checkout-shipping">
                                <h6>subTotal: </h6>
                                <p>Ksh {cart.cartTotalAmount}</p>
                            </div>
                            <div className="checkout-shipping">
                                <h6>+ Delivery Fee </h6>
                                <h6>+ Shipping Fee </h6>
                                <h6>+ Tax & VAT </h6>
                                <p>Ksh {taxableFee}</p>
                            </div>
                            <div className="checkout-total">
                                <h6>Total</h6>
                                <p>Ksh: {totalCost}</p>
                            </div>

                        </div >
                    )
                    }


                    <div className="checkout-total pay-button">
                        <button>Pay Now</button>
                    </div>
                </section >
            </div >
        </div >
    )
}

export default Order