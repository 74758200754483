import React, { useState } from 'react'
import './categories.css'
import Plane from './../../../assets/plane.png'
import { ProductsData } from './../../../data/products'
// import autoAnimate from '@formkit/auto-animate'

function ProductCategories() {


    const [menuProducts, setMenuProducts] = useState(ProductsData)
    const filter = (type) => {
        setMenuProducts(ProductsData.filter((product) => product.type === type))
    }


    return (
        <div className='categories-main'>
            <img src={Plane} alt="" />
            <h1>Our Featured Products</h1>


            <div className="products-options">
                <ul className="menu">
                    <li onClick={() => setMenuProducts(ProductsData)}>All</li>
                    <li onClick={() => filter("skin care")}>Skincare</li>
                    <li onClick={() => filter("conditioner")}>Conditioners</li>
                    <li onClick={() => filter("foundation")}>Fundations</li>
                </ul>
                <div className="product-list">
                    {
                        menuProducts.map((product, i) => (
                            <div className="product">
                                <div className="left-s">
                                    <div className="name">
                                        <span>{product.name}</span>
                                        <span>{product.detail}</span>
                                    </div>
                                    <span>{product.price} Ksh</span>
                                    <div className="show">Shop Now</div>
                                </div>
                                <img src={product.img} alt="product" className='img-p' />
                            </div>
                        ))
                    }
                </div>
            </div>


        </div>
    )
}

export default ProductCategories